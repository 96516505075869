<template>
  <div>
    <b-overlay
      :show="showOverlay"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <!-- Search -->
          <div>
            <b-row>
              <b-col
                cols="4"
              >
                <div
                  style="z-index: 1"
                >
                  <label class="mr-1">Localidad: </label>
                  <v-select
                    v-model="localidad"
                    label="name"
                    :options="localidades"
                    @input="filterData"
                  />
                </div>
              </b-col>
              <b-col cols="3" />
              <b-col
                cols="5"
                style="padding-top: 24px"
              >
                <router-link
                  :to="{ name: redirectEdit, params: { id: null, origen: 'List Empresas', action: 'Create' } }"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                  >
                    <span class="text-nowrap">Agregar Punto de Iluminación</span>
                  </b-button>
                </router-link>
              </b-col>
            </b-row>
            <br>
          </div>
        </div>
        <div
          class="mx-2 mb-2"
          style="margin-top: -35px; position: relative;"
        >
          <b-row>
            <b-col cols="11">
              <div>
                <b-form-group>
                  <label>Buscar: </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Buscar"
                    type="text"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              style="margin-top: 4px"
              cols="1"
            >
              <br>
              <table>
                <tr>
                  <td style="padding-top: 4px; padding-right: 15px;">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-primary
                      class="btn-icon"
                      title="Buscar"
                      variant="gradient-primary"
                      @click="loadPostes()"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </td>
                  <td
                    v-if="user.company.enabledReport"
                    style="padding-top: 4px;"
                  >
                    <reporte-general
                      :rows="rowsReporte"
                      :columns="columnsReporte"
                      :titulo-principal="tituloPrincipal"
                      :titulo-secundario="tituloSecundario"
                      :subtitulo="subtitulo"
                      :titulo-descarga="tituloDescarga"
                    />
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <list-table-data
                :table-columns="tableColumns"
                :table-data="items"
                :redirect-edit="redirectEdit"
                :redirect-show="redirectShow"
                :origen="catalogoName"
                :show-search="false"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import ListTableData from '@/components/ListTableData.vue'
import { countPostesFilter, getPostesFilter } from '@/utils/postes'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getLocalidades } from '@/utils/localizacion'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  getColumns, getRows, subtitulo, tituloDescarga, tituloPrincipal, tituloSecundario,
} from '@/utils/reportes/administracion/puntosIluminacion'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    ReporteGeneral,
    ListTableData,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    vSelect,
    BFormGroup,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      catalogoName: 'Puntos de Iluminiación',
      redirectEdit: 'puntos-iluminacion-show',
      redirectShow: 'puntos-iluminacion-show',
      items: [],
      user: JSON.parse(localStorage.getItem('userData')),
      localidad: {},
      localidades: [],
      tableColumns: [
        {
          label: 'Número de Poste',
          field: 'numeroPoste',
        },
        {
          label: 'Serie Lámpara',
          field: 'serieLampara',
        },
        {
          label: 'Potencia',
          field: 'potencia',
        },
        {
          label: 'Localidad',
          field: 'obj_localidad.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      columnsReporte: [],
      rowsReporte: [],
      searchTerm: '',
      showOverlay: false,
      tecnologias: [],
      modelos: [],
      grupos: [],
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
  },
  async beforeMount() {
    try {
      this.localidades = await getLocalidades(undefined, this.user.company.municipio)
      this.tecnologias = await getlistado(4, 'Tecnogología Luminarias', false, this.user)
      this.modelos = await getlistado(3, 'Modelos', false, this.user)
      this.grupos = await getlistado(12, 'Grupos', false, this.user)
      this.items = []
      if (this.localidades === null) this.localidades = []
      if (this.localidades.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.localidad = this.localidades[0]
        await this.loadPostes()
      }
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.localidades = []
      this.items = []
    }
  },
  methods: {
    async loadPostes() {
      this.items = []
      this.showOverlay = true
      const filtro = {
        where: {
          company: this.user.idCompany,
          and: [],
        },
      }

      if (this.localidad && this.searchTerm.trim() === '') {
        filtro.where.localidad = this.localidad.id
      }

      if (this.searchTerm.trim() !== '') {
        const searchTermFilter = {
          or: [
            { numeroPoste: { like: this.searchTerm.trim(), options: 'i' } },
            { serieLampara: { like: this.searchTerm.trim(), options: 'i' } },
          ],
        }
        filtro.where.and.push(searchTermFilter)
      }

      if (filtro.where.and.length === 0) {
        delete filtro.where.and
      }

      this.searchTerm = ''

      filtro.fields = {
        id: true,
        serieLampara: true,
        potencia: true,
        numeroPoste: true,
        localidad: true,
        objGrupo: true,
        tipoLuminaria: true,
        tecnologiaLampara: true,
        modeloLampara: true,
        calleAvenida: true,
        numeral: true,
        obj_localidad: true,
        numeroContador: true,
        latitud: true,
        longitud: true,
        observaciones: true,
      }
      // primero hay que contar
      // luego hay que ir a traer de 4000 en 4000 hasta llegar al total de la cuenta
      const total = await countPostesFilter(filtro.where)
      let inicio = 0
      const limit = 4000

      for (inicio = 0; inicio <= total.count; inicio += limit) {
        filtro.skip = inicio
        filtro.limit = limit

        try {
          // eslint-disable-next-line no-await-in-loop
          const postes = await getPostesFilter(filtro, true, this.localidades)
          if (postes && postes.length > 0) {
            this.items = this.items.concat(postes)
          }
        } catch (error) {
          console.error('Error al obtener postes:', error)
          break
        }
      }
      await this.loadDataExcel()
      this.showOverlay = false
    },
    async loadDataExcel() {
      this.rowsReporte = await getRows(this.items, this.tecnologias, this.modelos, this.grupos)
      this.columnsReporte = getColumns()
    },
    async filterData() {
      await this.loadPostes()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
